<template>
  <b-row no-gutters class="h-100 template-one">
    <b-col
      cols="12"
      sm="6"
      xl="8"
      class="p-5 d-none d-sm-flex justify-content-center align-items-center background-container"
      :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
    ></b-col>
    <b-col
      cols="12"
      sm="6"
      xl="4"
      class="bg-light p-5 d-flex flex-column justify-content-center align-items-center shadow-lg"
    >
      <div class="text-center my-auto">
        <img :src="logo" alt="logo" class="img-fluid mx-auto mb-4" />
        <!--
        <div class="title mb-3">{{ title }}</div>
        -->
        <div class="disclaimer font-weight-light">
          <vue-typed-js class="d-block" :loop="true" :strings="disclaimer">
            <span class="typing"></span>
          </vue-typed-js>
        </div>
      </div>
      <div class="mt-auto text-center social-media-container">
        <img
          :src="require('@/assets/linkedin-logo.png')"
          alt="LinkedIn logo"
          class="linkedin-logo mx-auto d-block mb-3"
        />
        <a
          v-if="linkedin1"
          :href="linkedin1"
          target="_blank"
          class="text-body mx-2 my-3"
          >Connect with Carolina</a
        >
        <br />
        <a
          v-if="linkedin2"
          :href="linkedin2"
          target="_blank"
          class="text-body mx-2 my-3"
          >Connect with Julia</a
        >
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TemplateOne",
  data() {
    return {
      logo: require("@/assets/logo.png"),
      backgroundImage: require("@/assets/bg.jpg"),
      title: process.env.VUE_APP_TITLE,
      disclaimer: [
        process.env.VUE_APP_DISCLAIMER_FIRST_LINE,
        process.env.VUE_APP_DISCLAIMER_SECOND_LINE,
      ],
      linkedin1: process.env.VUE_APP_LINKEDIN1,
      linkedin2: process.env.VUE_APP_LINKEDIN2,
    };
  },
};
</script>

<style lang="scss" scoped>
.template-one {
  .title {
    font-size: 3rem;
  }
  .disclaimer {
    font-size: 1.5rem;
  }
  .background-container {
    background-position: center center;
    background-size: cover;
    box-shadow: inset 0rem 0rem 5rem rgba(0, 0, 0, 0.2) !important;
  }
  .social-media-container {
    a {
      transition: all 1s;
      &:hover {
        color: gray !important;
        text-decoration: none;
      }
    }
  }
  .linkedin-logo {
    width: 50px; // Adjust the width as needed
    height: auto; // Maintain aspect ratio
    margin-bottom: 20px; // Space between the logo and the links
  }
}
</style>
